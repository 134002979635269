import { IOrganisation } from '@/types';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class DataLayerOrganisationObjectReady implements IEvent {
	public static EventType = 'DataLayerOrganisationObjectReady';
	public eventType: string = DataLayerOrganisationObjectReady.EventType;

	public readonly timestamp: Date;

	constructor(public readonly organisation: IOrganisation) {
		this.timestamp = new Date();
	}
}
