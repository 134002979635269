import { IPage } from '@/types';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class DataLayerPageObjectReady implements IEvent {
	public static EventType = 'DataLayerPageObjectReady';
	public eventType: string = DataLayerPageObjectReady.EventType;

	public readonly timestamp: Date;

	constructor(public readonly page: IPage) {
		this.timestamp = new Date();
	}
}
