import { IPage, IOrganisation, IStudy, IUser } from '@/types';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class DataLayerPageViewDispatched implements IEvent {
	public static EventType = 'DataLayerPageViewDispatched';
	public eventType: string = DataLayerPageViewDispatched.EventType;

	public readonly timestamp: Date;

	constructor(
		public readonly page: IPage,
		public readonly user: IUser,
		public readonly organisation: IOrganisation,
		public readonly study: IStudy,
	) {
		this.timestamp = new Date();
	}
}
