import { IStudy } from '@/types';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class DataLayerStudyObjectReady implements IEvent {
	public static EventType = 'DataLayerStudyObjectReady';
	public eventType: string = DataLayerStudyObjectReady.EventType;

	public readonly timestamp: Date;

	constructor(public readonly study: IStudy) {
		this.timestamp = new Date();
	}
}
