import { IPage } from '@/types';
import { Category } from '@/enum/page-category';
import { Environment } from '@/enum/environment';
import { PortalType } from '@/enum/portal-type';

export class Page implements IPage {
	public category?: Category;
	public portal?: PortalType;
	public environment?: Environment;
	public url?: string;
	public host?: string;
	public path?: string;
	public query?: string;
	public referrer?: string;

	constructor(data: IPage) {
		this.category = data.category;
		this.portal = data.portal;
		this.environment = data.environment;
		this.url = data.url;
		this.host = data.host;
		this.path = data.path;
		this.query = data.query;
		this.referrer = data.referrer;
	}
}
