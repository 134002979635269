"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversityCardImpressionData = void 0;
const ecommerce_tracking_data_class_1 = require("./ecommerce-tracking-data.class");
class UniversityCardImpressionData extends ecommerce_tracking_data_class_1.EcommerceTrackingData {
    constructor(productId, listPageType, listName, organisationId, listPosition) {
        super(productId, listPageType, listName, organisationId, undefined, undefined, listPosition, undefined, undefined);
        this.productId = productId;
        this.listPageType = listPageType;
        this.listName = listName;
        this.organisationId = organisationId;
        this.listPosition = listPosition;
    }
    getTrackingContext() {
        var _a, _b;
        return {
            organisationId: this.getProductId(),
            studyId: undefined,
            scholarshipId: undefined,
            bodyId: undefined,
            premiumnessLevel: (_b = (_a = this.premium) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
            price: undefined,
            position: this.listPosition,
            currency: this.getCurrency()
        };
    }
}
exports.UniversityCardImpressionData = UniversityCardImpressionData;
