"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductViewTracker = void 0;
class ProductViewTracker {
    constructor(tracker) {
        this.tracker = tracker;
    }
    trackImpression(trackingData, eventParams) {
        var _a, _b;
        this.tracker.trackProductView({
            eventParams,
            listEntityType: trackingData.listEntityType,
            listName: (_a = trackingData.listName) !== null && _a !== void 0 ? _a : '',
            listPageType: (_b = trackingData.listPageType) !== null && _b !== void 0 ? _b : '',
            item: trackingData.getTrackingContext()
        });
    }
}
exports.ProductViewTracker = ProductViewTracker;
