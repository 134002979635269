import { IDataLayer, IOrganisation, IPage, IStudy, IUser } from '@/types';

import {
	InternalEventDispatcher,
	InteractionEvent,
	PageviewEvent,
	PurchaseEvent,
} from '@/infrastructure/outbound';

export class EventDispatcher implements InternalEventDispatcher {
	private dataLayer: IDataLayer;

	constructor() {
		this.dataLayer = window['dataLayer'] as IDataLayer;
	}

	public dispatchPageviewEvent({
		page,
		study,
		organisation,
		user,
	}: {
		page: IPage;
		study?: IStudy;
		organisation?: IOrganisation;
		user?: IUser;
	}): void {
		this.dataLayer.push(new PageviewEvent(page, study, organisation, user));
	}

	public dispatchPurchaseEvent(params: { statisticsId: string }): void {
		this.dataLayer.push(
			new PurchaseEvent({
				eventName: 'referralClick',
				...params,
			}),
		);
	}

	public dispatchRegistrationEvent(params: {
		isStudyportalsEmployee?: boolean;
		provider?: string;
		referrer?: string;
	}): void {
		this.dataLayer.push(
			new InteractionEvent({
				eventName: 'registration',
				...params,
			}),
		);
	}
}
