export enum Category {
	ACCOUNT = 'account',
	ARTICLE = 'articles',
	CITY = 'cities',
	COUNTRY = 'countries',
	COUNTRY_TEST = 'country-test',
	COUNTRY_DEGREE = 'countries-degrees',
	DISCIPLINE = 'disciplines',
	HOME = 'home',
	META_RANKING = 'university-rankings',
	PERSONALITY_TEST = 'personality-test',
	RANKING = 'rankings',
	RANKING_COUNTRY = 'ranking-country',
	RANKINGS_REVIEW = 'rankings-reviews',
	SCHOLARSHIP = 'scholarships',
	SCHOLARSHIP_SEARCH = 'search-scholarships',
	SEARCH = 'search',
	STUDY = 'studies',
	STUDY_OPTIONS = 'study-options',
	UNIBUDDY = 'chat-with-students',
	UNIVERSITY = 'universities',
	VISA_INFO = 'visa-info',
}
