"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEntityType = void 0;
var ListEntityType;
(function (ListEntityType) {
    ListEntityType["STUDY"] = "study";
    ListEntityType["ORGANISATION"] = "organisation";
    ListEntityType["SCHOLARSHIP"] = "scholarship";
    ListEntityType["CAREER"] = "career";
})(ListEntityType = exports.ListEntityType || (exports.ListEntityType = {}));
