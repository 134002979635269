"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerIncentiveImpressionData = void 0;
const ecommerce_tracking_data_class_1 = require("./ecommerce-tracking-data.class");
class PartnerIncentiveImpressionData extends ecommerce_tracking_data_class_1.EcommerceTrackingData {
    constructor(productId, listPageType, listEntityType, listName, organisationId, disciplines, premium, listPosition, unitPrice, currency) {
        super(productId, listPageType, listName, organisationId, disciplines, premium, listPosition, unitPrice, currency);
        this.productId = productId;
        this.listPageType = listPageType;
        this.listEntityType = listEntityType;
        this.listName = listName;
        this.organisationId = organisationId;
        this.disciplines = disciplines;
        this.premium = premium;
        this.listPosition = listPosition;
        this.unitPrice = unitPrice;
        this.currency = currency;
    }
    getTrackingContext() {
        var _a, _b;
        return {
            organisationId: this.getOrganisationId(),
            studyId: this.getProductId(),
            scholarshipId: undefined,
            bodyId: undefined,
            premiumnessLevel: (_b = (_a = this.premium) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
            price: undefined,
            position: this.listPosition,
            currency: this.getCurrency()
        };
    }
}
exports.PartnerIncentiveImpressionData = PartnerIncentiveImpressionData;
