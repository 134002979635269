import { DataLayerObject, IState, IStateKey } from '@/types';

export class State implements IState {
	private stateKeys: IStateKey[] = [];

	constructor(
		...objects: {
			name: string;
			object?: DataLayerObject;
		}[]
	) {
		objects.forEach((object) => {
			this.stateKeys.push({
				name: object.name,
				object: object.object,
				isSet: false,
			});
		});
	}

	set(name: string, object: DataLayerObject): void {
		if (!this.exists(name)) {
			throw new Error(`Object with name ${name} does not exist on State.`);
		}

		const index = this.stateKeys.findIndex(
			(stateKey) => stateKey.name === name,
		);

		this.stateKeys[index].object = object;
		this.stateKeys[index].isSet = true;
	}

	get(name: string): DataLayerObject | undefined {
		if (!this.exists(name)) {
			throw new Error(`Object with name ${name} does not exist on State.`);
		}

		const index = this.stateKeys.findIndex(
			(stateKey) => stateKey.name === name,
		);

		return this.stateKeys[index].object;
	}

	isSet(name: string): boolean {
		if (!this.exists(name)) {
			throw new Error(`Object with name ${name} does not exist on State.`);
		}

		const object = this.stateKeys.find((stateKey) => stateKey.name === name);
		return object ? object.isSet : false;
	}

	allSet(): boolean {
		return this.stateKeys.every((stateKey) => stateKey.isSet);
	}

	private exists(name: string): boolean {
		return this.stateKeys.some((stateKey) => stateKey.name === name);
	}
}
