import { IOrganisation, IPage, IStudy, IUser } from '@/types';

import { IEvent } from '@/types';
import { EventType } from '@/enum';

export class PageviewEvent implements IEvent {
	public event: EventType = EventType.PAGE_VIEW;

	constructor(
		public readonly page: IPage,
		public readonly study?: IStudy,
		public readonly organisation?: IOrganisation,
		public readonly user?: IUser,
	) {}
}
