import {
	AnonymousStudentProfileSynced,
	AnonymousStudentServiceReady,
	IAnonymousStudentService,
	StudentRepositoryStateType,
} from '@studyportals/anonymous-student-interfaces';

import {
	ISessionService,
	IWishlistService,
	SessionServiceReadyEvent,
	WishlistServiceReadyEvent,
} from '@studyportals/student-interfaces';

import {
	IEventAggregationService,
	ISubscriber,
} from '@studyportals/event-aggregation-service-interface';

import { StudentField } from '@studyportals/studentdomain';
import { IExperiment, IUser, StudentDataRequiredServicesReady } from '@/types';
import { User, WishlistItem } from '@/domain';
import { DataLayerUserObjectReady } from '@/domain';
import {
	EventDispatcher,
	InternalEventDispatcher,
} from '@/infrastructure/outbound';

export class StudentDataAggregationService implements ISubscriber<StudentDataRequiredServicesReady | AnonymousStudentProfileSynced> {
	private student: IUser;
	private eventDispatcher: InternalEventDispatcher;
	private eventAggregationService: IEventAggregationService = window[
		'EventAggregationService'
	] as IEventAggregationService;

	private anonymousStudentService?: IAnonymousStudentService;
	private wishlistService?: IWishlistService;
	private sessionService?: ISessionService;

	private provider?: string;
	private userRegistered = false;

	constructor() {
		this.student = new User();

		this.eventDispatcher = new EventDispatcher();

		this.eventAggregationService.subscribeTo(
			AnonymousStudentServiceReady.EventType,
			this,
			true,
		);
		this.eventAggregationService.subscribeTo(
			WishlistServiceReadyEvent.EventType,
			this,
			true,
		);
		this.eventAggregationService.subscribeTo(
			SessionServiceReadyEvent.EventType,
			this,
			true,
		);
		this.eventAggregationService.subscribeTo(
			AnonymousStudentProfileSynced.EventType,
			this,
			true,
		);

		this.listenToStudentRegistration();
		this.listenToExperimentInitiation();
	}

	async notify(
		event: StudentDataRequiredServicesReady | AnonymousStudentProfileSynced,
	): Promise<void> {
		if (event.eventType === AnonymousStudentServiceReady.EventType) {
			this.setAnonymousStudentService(event as AnonymousStudentServiceReady);
		}

		if (event.eventType === WishlistServiceReadyEvent.EventType) {
			this.setWishlistService(event as WishlistServiceReadyEvent);
		}

		if (event.eventType === SessionServiceReadyEvent.EventType) {
			this.setSessionService(event as SessionServiceReadyEvent);
		}

		if (event.eventType === AnonymousStudentProfileSynced.EventType) {
			if (
				(event as AnonymousStudentProfileSynced).state !==
				StudentRepositoryStateType.ONLINE ||
				!this.userRegistered
			) {
				return;
			}

			const data = await this.getStudentRegistrationData();

			if (data) {
				this.eventDispatcher.dispatchRegistrationEvent(data);
				return;
			}
		}

		if (
			this.anonymousStudentService &&
			this.wishlistService &&
			this.sessionService
		) {
			await this.setStudentData();
			await this.setWishlist();
			await this.setSessionData();

			this.notifyStudentObjectReady();
		}
	}

	private setAnonymousStudentService(
		event: AnonymousStudentServiceReady,
	): void {
		this.anonymousStudentService = event.anonymousStudentService;
	}

	private async setStudentData(): Promise<void> {
		if (!this.anonymousStudentService) {
			return;
		}

		try {
			const studentData = await this.anonymousStudentService.getStudentData([
				StudentField.EMAIL,
				StudentField.GENDER,
				StudentField.BIRTH_DATE,
				StudentField.NATIONALITY_COUNTRY_ISO,
				StudentField.RESIDENCE_COUNTRY_ID,
				StudentField.INTERESTS_DISCIPLINES,
				StudentField.INTERESTS_COUNTRIES,
				StudentField.START_PERIOD_DATE,
				StudentField.ATTENDANCE,
				StudentField.CURRENCY,
				StudentField.TUITION_BUDGET,
				StudentField.LIVING_BUDGET,
				StudentField.STUDY_LEVEL,
				StudentField.STUDY_COUNTRY_ID,
				StudentField.ORIGIN_ORGANISATION_ID,
				StudentField.DISCIPLINES,
				StudentField.GPA,
				StudentField.WORK_EXPERIENCE,
				StudentField.EMAILING_FAVOURITES,
				StudentField.PROFICIENCY_TYPE,
			]);

			this.student.setStudentData(studentData);
		} catch (error) {
			console.error(
				"StudentDataManager: Failed to retrieve student's data.",
				error,
			);
		}
	}

	private setWishlistService(event: WishlistServiceReadyEvent): void {
		this.wishlistService = event.wishlistService;
	}

	private async setWishlist(): Promise<void> {
		if (!this.wishlistService) {
			return;
		}

		const wishlist = await this.wishlistService.getWishlist();

		const favourites = wishlist.favourites.map((f) => {
			return new WishlistItem(f.study.id, f.study.card?.getTitle());
		});

		this.student.setWishlist(favourites);
	}

	private setSessionService(event: SessionServiceReadyEvent): void {
		this.sessionService = event.sessionService;
	}

	private async setSessionData(): Promise<void> {
		if (!this.sessionService) {
			return;
		}

		const session = await this.sessionService.getSession();
		if (!session) {
			this.student.setSessionData(false, false);
			return;
		}

		const data = session.getUser();
		this.student.setSessionData(
			data.email ? true : false,
			this.isStudyportalsEmployee(data.email),
			data.identityId,
		);
	}

	private notifyStudentObjectReady(): void {
		const event = new DataLayerUserObjectReady(this.student);
		this.eventAggregationService.publishTo(
			DataLayerUserObjectReady.EventType,
			event,
		);
	}

	private async getStudentRegistrationData(): Promise<
	| {
		isStudyportalsEmployee?: boolean;
		referrer?: string;
		provider?: string;
	}
	| undefined
	> {
		if (!this.anonymousStudentService) {
			return;
		}

		const data = await this.anonymousStudentService.getStudentData([
			StudentField.EMAIL,
			StudentField.REFERRER,
		]);

		return {
			isStudyportalsEmployee: data[StudentField.EMAIL]
				? this.isStudyportalsEmployee(data[StudentField.EMAIL])
				: false,
			referrer: data[StudentField.REFERRER],
			provider: this.provider,
		};
	}

	private listenToStudentRegistration(): void {
		document.addEventListener('student-registered', (e) => {
			const event = e as CustomEvent<{
				provider: string;
			}>;

			this.provider = event.detail.provider;
			this.userRegistered = true;
		});
	}

	private readActiveExperiments(): void {
		const active_experiments = sessionStorage.getItem('sp_active_experiments');
		if (active_experiments !== null)
			this.student.abTests = JSON.parse(active_experiments) as IExperiment[];
	}

	private listenToExperimentInitiation(): void {
		document.addEventListener('dataLayer-sp-experiment-initiated', () => {
			this.readActiveExperiments();
		});
		this.readActiveExperiments();
	}

	private isStudyportalsEmployee(email: string): boolean {
		return email.split('@')[1] === 'studyportals.com';
	}
}
