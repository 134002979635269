"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudyCardImpressionData = void 0;
const ecommerce_tracking_data_class_1 = require("./ecommerce-tracking-data.class");
class StudyCardImpressionData extends ecommerce_tracking_data_class_1.EcommerceTrackingData {
    getTrackingContext() {
        var _a, _b;
        return {
            organisationId: this.getOrganisationId(),
            studyId: this.getProductId(),
            scholarshipId: undefined,
            bodyId: undefined,
            premiumnessLevel: (_b = (_a = this.premium) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
            price: this.getUnitPrice(),
            position: this.listPosition,
            currency: this.getCurrency()
        };
    }
}
exports.StudyCardImpressionData = StudyCardImpressionData;
