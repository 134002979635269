import { IDataLayer, IOrganisation, IPage, IStudy } from '@/types';
import { EventType } from '@/enum';

import {
	DataLayer,
	DataLayerOrganisationObjectReady,
	DataLayerPageObjectReady,
	DataLayerStudyObjectReady,
	OrganisationEventListeners,
	StudentDataAggregationService,
	StudyEventListeners,
} from '@/domain';

import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { IDataLayerClient } from '@/infrastructure/inbound';

export class DataLayerClient implements IDataLayerClient {
	private eventAggregationService: IEventAggregationService;

	constructor() {
		this.eventAggregationService = window[
			'EventAggregationService'
		] as IEventAggregationService;
	}

	initialiseDataLayer(): void {
		window['dataLayer'] = (window['dataLayer'] as IDataLayer) || [];

		this.bootDataLayerService();

		document.dispatchEvent(new Event(EventType.DATA_LAYER_INITIALISED));
	}

	sendPageData(page: IPage): void {
		this.eventAggregationService.publishTo(
			DataLayerPageObjectReady.EventType,
			new DataLayerPageObjectReady(page),
		);
	}

	sendStudyData(study: IStudy): void {
		this.eventAggregationService.publishTo(
			DataLayerStudyObjectReady.EventType,
			new DataLayerStudyObjectReady(study),
		);
	}

	sendOrganisationData(organisation: IOrganisation): void {
		this.eventAggregationService.publishTo(
			DataLayerOrganisationObjectReady.EventType,
			new DataLayerOrganisationObjectReady(organisation),
		);
	}

	private bootDataLayerService(): void {
		new StudyEventListeners();
		new OrganisationEventListeners();
		new StudentDataAggregationService();
		new DataLayer();
	}
}
