import {
	EventDispatcher,
	InternalEventDispatcher,
} from '@/infrastructure/outbound';
import {
	IEventAggregationService,
	ISubscriber,
} from '@studyportals/event-aggregation-service-interface';
import {
	DataLayerOrganisationObjectReady,
	DataLayerPageObjectReady,
	DataLayerPageViewDispatched,
	DataLayerStudyObjectReady,
	DataLayerUserObjectReady,
} from '@/domain';

import {
	DataLayerObject,
	IOrganisation,
	IPage,
	IState,
	IStudy,
	IUser,
	PageviewReadyToDispatch,
} from '@/types';

import { Page, State } from '@/domain';
import { Objects } from '@/enum';

export class DataLayer implements ISubscriber<PageviewReadyToDispatch> {
	private eventAggregationService: IEventAggregationService = window[
		'EventAggregationService'
	] as IEventAggregationService;
	private dispatcher: InternalEventDispatcher;
	private state: IState;

	constructor() {
		this.state = new State(
			{ name: Objects.PAGE },
			{ name: Objects.USER },
			{ name: Objects.ORGANISATION },
			{ name: Objects.STUDY },
		);

		this.dispatcher = new EventDispatcher();

		this.eventAggregationService.subscribeTo(
			DataLayerPageObjectReady.EventType,
			this,
			true,
		);
		this.eventAggregationService.subscribeTo(
			DataLayerUserObjectReady.EventType,
			this,
			true,
		);
		this.eventAggregationService.subscribeTo(
			DataLayerOrganisationObjectReady.EventType,
			this,
			true,
		);
		this.eventAggregationService.subscribeTo(
			DataLayerStudyObjectReady.EventType,
			this,
			true,
		);
	}

	notify(event: PageviewReadyToDispatch): void {
		if (event.eventType === DataLayerPageObjectReady.EventType) {
			this.handleDataLayerPageObjectReady(event as DataLayerPageObjectReady);
		}

		if (event.eventType === DataLayerUserObjectReady.EventType) {
			this.handleDataLayerUserObjectReady(event as DataLayerUserObjectReady);
		}

		if (event.eventType === DataLayerOrganisationObjectReady.EventType) {
			this.handleDataLayerOrganisationObjectReady(
				event as DataLayerOrganisationObjectReady,
			);
		}

		if (event.eventType === DataLayerStudyObjectReady.EventType) {
			this.handleDataLayerStudyObjectReady(event as DataLayerStudyObjectReady);
		}

		if (this.state.allSet()) {
			this.handleAllObjectsReady();
		}
	}

	private handleAllObjectsReady(): void {
		const page = this.state.get(Objects.PAGE) as IPage;
		const user = this.state.get(Objects.USER) as IUser;
		const organisation = this.state.get(Objects.ORGANISATION) as IOrganisation;
		const study = this.state.get(Objects.STUDY) as IStudy;

		this.dispatcher.dispatchPageviewEvent({ page, user, organisation, study });

		this.eventAggregationService.publishTo(
			DataLayerPageViewDispatched.EventType,
			new DataLayerPageViewDispatched(page, user, organisation, study),
		);
	}

	private handleDataLayerPageObjectReady(
		event: DataLayerPageObjectReady,
	): void {
		const page = event.page;
		this.setObject(Objects.PAGE, new Page(page));
	}

	private handleDataLayerUserObjectReady(
		event: DataLayerUserObjectReady,
	): void {
		const student = event.student;
		this.setObject(Objects.USER, student);
	}

	private handleDataLayerOrganisationObjectReady(
		event: DataLayerOrganisationObjectReady,
	): void {
		const organisation = event.organisation;
		this.setObject(Objects.ORGANISATION, organisation);
	}

	private handleDataLayerStudyObjectReady(
		event: DataLayerStudyObjectReady,
	): void {
		const study = event.study;
		this.setObject(Objects.STUDY, study);
	}

	private setObject(name: string, object: DataLayerObject): void {
		try {
			this.state.set(name, object);
		} catch (error) {
			console.error(
				`DataLayer: an error occurred when setting object with name ${name}: `,
				error,
			);
		}
	}
}
